.container {
  composes: main-container flex from global;
  flex-direction: column;
  justify-content: center;
  padding: 32px 0 128px;
}

.info-wrapper {
  composes: mb32 from global;
  text-align: center;
}

.info-desc {
  composes: text-16 text-light lh-157 mb8 from global;
}

.info-title {
  composes: mb8 lh-138 text-24 text-dark font-medium from global;
}

.types-wrapper {
  composes: flex from global;
  justify-content: space-between;
}

@media (--screen-tabletOrLower) {
  .container {
    padding: 16px;
    margin-bottom: 16px;
  }

  .types-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .info-wrapper {
    text-align: left;
    margin-bottom: 32px;
  }

  .info-title {
    font-size: 20px;
  }

  .info-desc {
    text-align: left;
    font-size: 16px;
    max-width: 100%;
  }
}
