.container {
  composes: p16 mt16 mb48 br-4 from global;
  background-image: url(../../../../assets/svg/Open_Broker.svg);
  background-size: cover;
}

.heading {
  composes: font-medium text-18 from global;
  color: var(--text-color-dark);
}

.ctas {
  composes: mt24 from global;
}

.text-content {
  max-width: 84%;
  line-height: 20px;
}

.text {
  composes: mt8 from global;
  color: var(--text-color-normal);
}
