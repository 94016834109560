.container {
  composes: flex-between br-4 pl16 pr16 pt24 pb24 from global;
  align-items: center;
  background-image: url(../../assets/svg/View_smallcases.svg);
  background-size: cover;
}

.heading {
  composes: text-14 lh-169 from global;
  flex-basis: 50%;
  color: var(--text-color-dark);
}
