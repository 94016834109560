.content {
  margin: 0 auto;
  @media (--screen-mobile), (--screen-landscape) {
    max-width: 480px;
  }
  @media (--screen-tablet) {
    max-width: 600px;
  }
}

.grid-fold {
  composes: tablet-container from global;
  width: calc(100% - 64px);
  max-width: 1120px;
  margin: 0 auto 128px;
  @media (--screen-mobile), (--screen-landscape) {
    margin-bottom: 40px;
    padding: 0 16px;
  }
  @media (--screen-tablet) {
    max-width: 600px !important;
    margin-bottom: 64px;
  }
}
.video-carousel-container {
  margin-bottom: 128px;
}

.view-all-button {
  composes: text-blue text-14 font-medium from global;
}

.mobile-container {
  margin-bottom: 40px;
  @media (--screen-mobile) {
    margin-right: 16px;
    margin-left: 16px;
  }
}
