.nav {
  composes: flex from global;
}

.broker-logo-container {
  composes: flex relative pointer reset-button-styles mr8 from global;
  font-family: inherit;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.05),
    0 2px 6px 0 rgba(0, 0, 0, 0.04);
  background-color: var(--color-white);
  color: var(--color-blue);
}

.broker-logo-container:hover {
  transform: translateY(-1px);
  transition: transform 0.1s ease-out;
}

.broker-tooltip {
  composes: pl8 pr8 absolute z-order-three br-4 from global;
  display: none;
  white-space: nowrap;
  margin-top: -10px;
  left: -10px;
  color: var(--color-white);
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: var(--color-black);
}

.broker-logo-container:hover .broker-tooltip {
  display: block;
  top: -20px;
}

.broker-count {
  composes: font-medium text-14 from global;
}

.broker-logo {
  max-width: 24px;
}

@media (--screen-mobile), (--screen-smaller) {
  .broker-logo-container {
    height: 32px;
    width: 32px;
  }

  .broker-logo {
    max-width: 20px;
  }

  .broker-count {
    font-size: 12px;
  }
}
