.row {
  composes: flex-apart mt20 from global;
}
.item {
  composes: flex-start from global;
}
.desc {
  composes: ml16 pl24 hidden mt4 text-13 text-normal lh-157 from global;

  color: var(--text-color-light);
}
.title {
  composes: ml16 from global;
  color: var(--text-color-dark);
}
.icon {
  composes: text-24 from global;
  margin-top: 2px;
}
.active {
  composes: block from global;
}

.blue {
  color: var(--color-blue);
}
