.container {
  composes: full-width mt56 flex-start from global;
  margin-bottom: 112px;
  /*
  * After upgrading css-loader to version greater than 2.0.0 the ~ alias 
  * setup stopped working in css files. So currently using relative path
  * as a workaround.
  * https://github.com/webpack-contrib/css-loader/releases/tag/v2.0.0
  */
  background-image: url(../../../../assets/svg/Intro_banner_desktop.svg);
  background-size: cover;
  background-position: center;
  height: 500px;
  @media (--screen-mobile), (--screen-landscape) {
    background-image: url(../../../../assets/svg/intro_banner_mweb.svg);
    height: 600px;
    margin-bottom: 40px;
  }
  @media (--screen-tablet) {
    margin-bottom: 40px;
  }
}

.content {
  composes: main-container from global;
  @media (--screen-mobile), (--screen-landscape) {
    margin-left: 16px;
    margin-bottom: 96px;
    max-width: 300px;
  }
  @media (--screen-landscape) {
    margin-left: calc((100% - 480px) / 2);
  }
  @media (--screen-tablet) {
    max-width: 600px;
    margin: 0 auto;
  }
}
.ctas {
  composes: flex pt24 from global;
  justify-content: flex-start;
}

.heading {
  composes: display from global;
  @media (--screen-mobile), (--screen-landscape) {
    font-size: 28px;
  }
}

.text {
  composes: pt12 text-20 lh-138 from global;
  @media (--screen-mobile), (--screen-landscape) {
    font-size: 14px;
  }
}
