.video-section {
  composes: flex from global;
  align-items: center;
  flex-direction: column;
}

.text-container {
  composes: flex from global;
  flex-direction: column;
}

.video-text-first {
  composes: text-18 mb8 text-light from global;
  @media (--screen-tabletOrLower) {
    font-size: 16px !important;
  }
}
.video-text-second {
  composes: text-18 text-normal lh-169 mb16 from global;
  @media (--screen-tabletOrLower) {
    font-size: 16px !important;
  }
}
.youtube-video-wrapper {
  width: 100%;
  margin-left: -10px;
  @media (--screen-tabletOrLower) {
    margin-left: 0;
  }
}

.video-frame {
  composes: br-4 mb64 from global;
  height: 412px;
  width: 732px;
  box-shadow: 0 32px 46px 2px rgba(0, 0, 0, 0.12);
  @media (--screen-tabletOrLower) {
    width: calc(100vw - 32px);
    height: calc((100vw - 32px) / 1.778);
  }
}

.thumbnail-wrapper {
  composes: relative full-width from global;
  max-height: 310px;
  border: none;
  background: none;
  padding: 0;

  @media (--screen-tablet) {
    max-height: 258px;
  }
  @media (--screen-mobile), (--screen-smaller) {
    max-height: 185px;
  }
}

.video-thumbnail {
  composes: pointer br-4 from global;
  @media (--screen-tabletOrLower) {
    width: 100%;
  }
}
