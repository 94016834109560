.container {
  composes: p16 br-4 from global;
  max-width: 250px;
  opacity: 0;
  transform: translateX(16px);
  transition: all 0.2s ease-out;
  background-color: var(--color-white);
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.08);
}

.container::after {
  content: '';
}

.container:hover {
  box-shadow: 0 1px 2px 3px rgba(0, 0, 0, 0.1);
}

.title-text {
  composes: mb8 from global;
}

.container:hover .title-text {
  color: var(--color-blue);
}

.card-head {
  composes: full-width from global;
}

.smallcase-img {
  composes: img-64 mb12 br-4 from global;
}

.desc-text {
  composes: text-normal mb12 lh-157 from global;
}

.growth-rate {
  composes: flex from global;
  justify-content: space-between;
}

.g-rate {
  composes: text-normal mr24 from global;
}

.g-rate-text {
  composes: text-16 from global;
  font-weight: 600;
  letter-spacing: 0.42px;
  margin-bottom: 0;
}

.arrow-right {
  composes: absolute from global;
  top: 40%;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid var(--color-white);
}

.card-appear {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.card-disappear {
  opacity: 0 !important;
  transform: translateX(16px) !important;
}

.card-btn {
  composes: reset-button-style pointer from global;
  text-align: left;
  font-family: inherit;
}

@media only screen and (max-width: 480px) {
  .container {
    transform: translateY(32px);
    max-width: 100%;
  }

  .desc-text {
    line-height: 1.44;
  }

  .desc-text,
  .smallcase-img {
    margin-bottom: 0;
  }

  .growth-rate {
    display: none;
  }

  .card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .smallcase-img {
    margin-right: 16px;
  }

  .card-appear {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }

  .card-disappear {
    opacity: 0 !important;
    transform: translateY(32px) !important;
  }

  .arrow-right {
    display: none;
  }
}
