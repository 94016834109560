.wrapper {
  composes: pointer inline-block relative card-transition br-4 p12 mr16 from global;
  max-width: 240px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
  text-overflow: ellipsis;
  border: solid 1px var(--color-border);
  background-color: var(--color-white);
}

.volatility-container-shimmer {
  composes: shine mb8 from global;
  width: 50px;
  height: 18px;
}

.volatility-container {
  composes: absolute pt4 pb4 pl8 pr8 text-12 br-4 text-dark font-medium from global;
  top: 28px;
  right: 0;
}

.volatility-container-low {
  background-color: var(--low-risk-bg);
}

.volatility-container-medium {
  background-color: var(--moderate-risk-bg);
}

.volatility-container-high {
  background-color: var(--high-risk-bg);
}

.img-shimmer {
  composes: img-48 shine br-4 from global;
  border: none;
}

.info-container {
  composes: mb16 from global;
}

.img-container {
  composes: mb12 from global;
}

.name-container {
  composes: row from global;
  align-items: flex-start;
}

.name-shimmer {
  composes: shine mb8 from global;
  width: 150px;
  height: 24px;
}

.smallcase-name {
  composes: ellipsis text-16 text-dark font-medium mb8 lh-138 from global;
  max-width: 200px;
}

.description-shimmer {
  composes: shine from global;
  width: 200px;
  height: 15px;
}

.smallcase-description {
  min-height: 64px;
  composes: text-13 text-normal lh-157 from global;
}

.stat-container {
  composes: flex mb8 from global;
  gap: 24px;
}

.watchlist-icon-container {
  composes: ml16 from global;
  margin-top: 2px;
}

.statbox-button {
  composes: pointer from global;
  text-align: unset;
}

@media (--screen-desktop) {
  .wrapper:hover .smallcase-name {
    color: var(--color-blue);
  }
}
