.featured-stories-container {
  composes: pointer p12 mt32 br-4 flex full-width from global;
  background-color: var(--banner-bg);
  align-items: center;
  color: var(--text-color-dark);
}

.featured-stories-container:hover {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.06);
}

.featured-story-image {
  composes: br-4 from global;
  width: 91px;
  height: 67px;
}
.featured-stories-texts-container {
  composes: p16 flex from global;
  flex-direction: column;
  @media (--screen-tabletOrLower) {
    text-align: left;
  }
}

.featured-story-title {
  composes: text-13 pb8 font-semibold from global;
  color: var(--color-blue);
}
.featured-story-sub-title {
  composes: text-18 font-semibold from global;
}
