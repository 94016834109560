.slider-wrapper {
  composes: main-container mb16 from global;
  flex-direction: column;
}

.cta-text {
  composes: mb24 lh-138 from global;
}

.title-txt {
  composes: lh-157 text-16 mt4 text-medium from global;
}

.heading-text {
  composes: mt8 mb8 text-24 text-dark font-medium from global;
}

.ideas-text {
  /* added z-index because text was coming below illustration
  - illustration in LandingDC.css */
  z-index: 1;
}

.card-wrapper {
  composes: relative pointer flex-column br-4 mr16 p32 from global;
  width: 532px;
  border: 1px solid var(--color-border);
  align-items: flex-start;
  background-color: var(--color-white);
  height: 186px;
}

.illustration-right {
  position: absolute;
  right: 0;
  bottom: 0;
}

.illustration-left {
  position: absolute;
  bottom: 0;
  right: 150px;
}

.slider-container {
  composes: main from global;
}

@media (--screen-tabletOrLower) {
  .card-wrapper {
    width: unset;
    height: 247px;
    max-width: 240px;
    display: unset;
    text-align: center;
  }

  .illustration-right {
    height: 60px;
  }

  .illustration-left {
    left: 0;
    height: 60px;
  }
  .title-txt {
    margin-bottom: 0;
  }
  .slider-container {
    margin-top: 0;
  }
  .heading-text {
    font-size: 20px;
  }
  .slider {
    margin-left: -16px;
  }
}
