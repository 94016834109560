.initial-text {
  composes: text-center text-18 sm-text-mbl mb8 lh-157 from global;
  @media (--screen-tabletOrLower) {
    text-align: center;
    max-width: 260px;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 64px;
  }
}

.effective-header {
  composes: display text-center mb64 from global;
  @media (--screen-tabletOrLower) {
    margin-bottom: 48px;
    text-align: center;
    max-width: 260px;
    margin-left: 32px;
    margin-right: 32px;
  }
}

.info-row-container {
  composes: flex-column p16 from global;
  align-items: center;
}

.info-row {
  composes: flex from global;
  align-items: center;
  max-width: 530px;
  transition: all 0.3s ease-out;
  @media (--screen-tabletOrLower) {
    flex-direction: column;
  }
}

.info-row-fig {
  composes: flex from global;
  min-height: 200px;
  min-width: 193px;
  margin-left: -30px;
  margin-right: 18px;
  @media (--screen-tabletOrLower) {
    margin-right: 0;
    margin-left: 36px;
  }
}

.info-header {
  composes: text-20 lh-169 mb8 from global;
  @media (--screen-tabletOrLower) {
    font-size: 18px;
  }
}

.info-content {
  @media (--screen-tabletOrLower) {
    margin-bottom: 48px;
  }
}

.info-text {
  composes: text-16 lh-169 mb8 from global;
}

.info-row-img {
  @media (--screen-tabletOrLower) {
    margin-bottom: 12px;
  }
}
