.main-container {
  border-bottom: 1px solid var(--color-border);
}

.container {
  composes: main-container from global;
  margin-bottom: 160px;
  flex-direction: row;
  justify-content: space-between;
}

.card-init {
  opacity: 0;
  transform: translateX(32px);
}

.card-final {
  opacity: 1;
  transform: translateX(0);
}

.video-section {
  composes: flex mb24 from global;
  align-items: flex-start;
  flex-direction: column;
}

.featured-story-image {
  composes: br-4 from global;
  width: 91px;
  height: 67px;
}

.video-text-first {
  composes: text-18 mb8 text-light from global;
}

.video-text-second {
  composes: text-18 text-normal lh-169 mb16 from global;
}

.text-container {
  composes: flex from global;
  flex-direction: column;
  max-width: 480px;
}

.info-row {
  composes: flex from global;
  align-items: center;
  max-width: 530px;
  transition: all 0.3s ease-out;
}

.info-header {
  composes: text-20 lh-169 mb8 from global;
}

.info-text {
  composes: text-16 lh-169 mb8 from global;
}

.card-container {
  composes: flex mt64 from global;
  justify-content: space-between;
}

.card {
  width: 545px;
  padding: 48px;
  border-radius: 8px 8px 0 0;
}

.blue-card {
  background: url(/public/static/images/svg/blue-bg.svg);
  background-position: 0 -32px;
  background-size: cover;
  transition: all 0.3 ease-out;
}

.yellow-card {
  background: url(/public/static/images/svg/yellow-bg.svg);
  background-position: 0 -32px;
  background-size: cover;
  transition: all 0.3 ease-out;
}

.compare-text {
  composes: text-18 text-light mb8 from global;
}

.compare-heading {
  composes: font-semibold mb16 lh-138 from global;
  font-size: 28px;
  max-width: 350px;
}

.compare-cta {
  composes: text-18 text-light from global;
}

.card span {
  font-size: 14px;
  color: var(--color-blue);
  font-weight: 500;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
}

.card span::after {
  content: '\2192';
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  right: -20px;
  top: 2px;
  transition: all 0.2s ease-in-out 0s;
}

.card span:hover::after {
  right: -25px;
}

.youtube-video-wrapper {
  width: 100%;
  max-width: 545px;
  margin-left: -10px;
}

.youtube-video {
  width: 100%;
  height: 300px;
}

.video-thumbnail {
  cursor: pointer;
  border-radius: 4px;
  max-width: 480px;
}

.video-frame {
  composes: br-4 mb64 from global;
  height: 412px;
  width: 732px;
  box-shadow: 0 32px 46px 2px rgba(0, 0, 0, 0.12);
}

.thumbnail-wrapper {
  composes: relative full-width from global;
  max-height: 310px;
  border: none;
  background: none;
  padding: 0;
}

.thumbnail-placeholder {
  width: 100%;
  height: 100%;
  background-color: var(--banner-bg);
}

.info-row-img {
  min-height: 200px;
  min-width: 193px;
  margin-left: -30px;
  margin-right: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.info-row-img img {
  max-width: 225px;
}

.info-row-container {
  composes: flex from global;
  flex-direction: column;
  justify-content: space-around;
}

/* play button */

.fake-btn {
  display: flex;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  height: 55px;
}

.fake-btn button[name='play'] {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 100%;
  margin: auto;
  cursor: pointer;
  background: var(--color-red);
}

.fake-btn button[name='play']::after {
  content: '';
  display: inline-block;
  position: relative;
  top: 1px;
  left: 3px;
  border-style: solid;
  border-width: 10px 0 10px 20px;
  border-color: transparent transparent transparent var(--color-white);
}

@media (--screen-tabletOrLower) {
  .youtube-video-wrapper {
    margin-left: 0;
  }

  .container {
    padding: 16px;
    margin-bottom: 0;
    flex-direction: column;
  }

  .video-frame {
    width: calc(100vw - 32px);
    height: calc((100vw - 32px) / 1.778);
  }

  .video-section {
    flex-direction: column;
  }

  .video-text-first {
    font-size: 16px !important;
  }

  .video-text-second {
    font-size: 16px !important;
  }

  .info-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .card-container {
    flex-direction: column;
    margin-top: 0 !important;
  }

  .card {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px;
  }

  .compare-text {
    font-size: 14px;
  }

  .compare-heading {
    font-size: 20px;
    max-width: 250px;
  }

  .compare-cta {
    font-size: 14px;
  }

  .info-header {
    font-size: 18px;
  }

  figure img {
    margin-bottom: 32px;
  }

  .youtube-video {
    width: 100%;
    margin: 0;
  }

  .video-thumbnail {
    width: 100%;
  }

  .card-init {
    opacity: 0;
    transform: translateY(16px);
  }

  .card-final {
    opacity: 1;
    transform: translateY(0);
    padding-top: 32px;
  }
}

@media (--screen-tablet) {
  .thumbnail-wrapper {
    max-height: 258px;
  }
}

@media (--screen-mobile), (--screen-smaller) {
  .thumbnail-wrapper {
    max-height: 185px;
  }
}
