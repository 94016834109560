.faq-content-title-holder {
  composes: text-center from global;
}

.subtitle {
  composes: lh-157 text-16 from global;
  text-align: center;
}

@media (--screen-desktop), (--screen-tablet) {
  .faq-content-container-outer {
    padding-left: 64px;
    padding-right: 64px;
  }

  .faq-content-title-holder {
    text-align: left;
    flex-basis: 50%;
  }
}

@media (--screen-desktop), (--screen-tablet) {
  .faq-content-container-outer {
    padding: 96px 64px;
  }
}

@media (--screen-landscape) {
  .faq-content-container-outer {
    padding: 48px 32px;
  }
}

@media (--screen-mobile), (--screen-smaller) {
  .faq-content-title-holder {
    width: 100%;
  }
}

.faq-content-container-inner {
  composes: flex relative full-width from global;
  justify-content: space-between;
  height: initial;
  margin: auto;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 1120px;
}

.faq-content-container-outer {
  padding: 48px 0;
}

.faq-content-title-holder {
  composes: pt16 from global;
}

.faq-content-title {
  composes: display mt8 text-dark font-semibold from global;
}
