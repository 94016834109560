.card {
  composes: flex mr12 from global;
  flex-direction: column;
  max-width: 256px;
  padding: 24px;
  border-radius: 4px;
  background-color: var(--color-white);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.05),
    0 4px 10px 0 rgba(0, 0, 0, 0.07);
}

.card:last-child {
  margin-right: 0;
}

.card:hover {
  transform: translateY(-2px) !important;
  transition: all 0.2s ease-out;
}

.image {
  composes: img-80 mb16 from global;
  border-radius: 4px;
}

.name {
  composes: text-18 font-medium lh-138 mb8 from global;
}

.desc {
  composes: text-14 text-normal lh-157 from global;
}

.card:hover .name {
  color: var(--color-blue);
}

.img-placeholder {
  composes: img-80 mb16 br-4 from global;
  background-color: var(--banner-bg);
}

@media (--screen-tabletOrLower) {
  .card {
    width: 100%;
    max-width: 100%;
    margin: 0 0 16px 0;
  }

  .image {
    width: 64px;
    height: 64px;
  }

  .img-placeholder {
    width: 64px;
    height: 64px;
  }

  .name {
    font-size: 18px;
  }

  .desc {
    font-size: 14px;
  }
}
