.pre-heading {
  color: var(--text-color-light);
}

.heading {
  composes: text-18 mt8 font-medium from global;
  color: var(--text-color-dark);
  width: 97%;
}

.header-section {
  composes: flex from global;
}
