.invest-ideas-container {
  composes: relative pt32 from global;
  overflow: hidden;
  background: radial-gradient(100% 50% at bottom, transparent 64%, #f4faff 48%);
}

.popular-ideas-container {
  composes: tablet-container p16 from global;
}

.illus-left,
.illus-right {
  composes: absolute from global;
  top: 0;
  left: 0;
  max-width: 350px;
  /* added z-index because illustration was coming on top of text */
  z-index: 0;
}

.illus-right {
  left: unset;
  right: 0;
}

@media (--screen-tabletOrLower) {
  .invest-ideas-container {
    padding-top: 0;
  }
  .illus-left {
    display: none;
  }
  .illus-right {
    max-width: 200px;
    right: -72px;
  }
}
