.container {
  padding-top: 128px;
  padding-bottom: 300px;
  box-shadow: 0 1px 1px 0 rgba(129, 135, 140, 0.17);
}

.info-text {
  composes: mb32 from global;
  text-align: center;
}

.info-text-one {
  composes: text-18 text-light mb8 from global;
}

.tweets-container {
  composes: mb64 from global;
  overflow-x: hidden;
}

.tweets-row {
  composes: flex from global;
}

.tweets-slider {
  animation: scroll 60s linear infinite;
}

.tweets-slider:hover {
  animation-play-state: paused !important;
}

.first-tweets-row {
  composes: mb16 from global;
  align-items: flex-end;
}

.second-tweets-row {
  align-items: flex-start;
}

.banner-container {
  composes: main-container from global;
  position: relative;
  margin-bottom: 24px;
}

.news-header {
  composes: text-18 mb12 text-light from global;
}

.news-container {
  width: 100%;
  text-align: center;
}

.icons-container {
  composes: flex from global;
  justify-content: space-between;
  align-items: center;
}

.channel {
  margin: 12px;
}

.icons-container figure {
  border: none;
  border-radius: 4px;
}

.icons-container figure:hover {
  background-color: var(--banner-bg);
  transition: background 0.15s ease-in;
}

.banner-wrapper {
  composes: flex from global;
  justify-content: space-between;
  background: var(--color-blue);
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  top: 180px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.banner-wrapper figure {
  max-width: 200px;
}

.cta-content {
  max-width: 576px;
  margin: 0 auto;
  padding: 64px 0;
  text-align: center;
}

.cta-header-text {
  composes: font-medium mb24 from global;
  color: var(--color-white);
}

.cta-desc-text {
  composes: text-18 lh-169 mb16 from global;
  color: #c5ddf4;
  letter-spacing: -0.01em;
}

.bank-cards {
  composes: flex from global;
  justify-content: center;
  flex-wrap: wrap;
}

.bank-card {
  composes: flex mb24 ml8 mr8 from global;
  justify-content: center;
  position: relative;
  background: var(--color-white);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
  max-width: 176px;
  width: 100%;
  height: 75px;
  border-radius: 4px;
  align-items: center;
}

.bank-card figure {
  line-height: 1;
  width: 120px;
}

.bank-card img {
  width: 100%;
  max-width: 200px;
}

.channel-placeholder {
  composes: text-13 text-light from global;
  width: 154px;
  line-height: 70px;
}

.broker-placeholder {
  composes: text-13 text-light from global;
  width: 120px;
  line-height: 30px;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-170%);
  }
}

@media (--screen-tabletOrLower) {
  .container {
    padding-top: 32px;
  }

  .tweets-container {
    overflow: scroll;
  }

  .tweets-slider {
    animation: unset;
  }

  .bank-cards {
    margin-bottom: 16px;
  }

  .bank-card {
    max-width: 135px;
    height: 55px;
    margin: 5px 8px;
  }

  .bank-card img {
    margin: 0;
    width: 90px;
  }

  .banner-wrapper {
    border-radius: unset;
    top: 220px;
  }

  .channel {
    width: 85px;
  }

  .banner-wrapper > figure {
    display: none;
  }

  .cta-content {
    padding: 24px 16px;
  }

  .cta-header-text {
    font-size: 25px;
  }

  .cta-desc-text {
    font-size: 16px;
  }

  .info-text {
    text-align: left;
    padding: 16px;
  }

  .info-text-one {
    font-size: 16px;
  }

  .icons-container {
    flex-wrap: wrap;
    padding: 0 16px;
  }

  .channel-placeholder {
    width: 109px;
    line-height: 55px;
  }

  .broker-placeholder {
    width: 90px;
    line-height: 20px;
  }
}

@media (--screen-smaller) {
  .bank-card {
    max-width: 120px;
    height: 35px;
    margin: 8px 8px;
  }
}
