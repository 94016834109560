.container {
  composes: main-container flex from global;
  align-items: center;
  justify-content: space-between;
  padding: 128px 0;
}

.info-wrapper {
  composes: flex from global;
  flex-direction: column;
}

.info-desc {
  composes: text-16 lh-169 mb24 from global;
  max-width: 500px;
}

.video-wrapper {
  composes: flex from global;
}

.creator-video {
  height: 400px;
  width: 110%;
}

@media (--screen-tabletOrLower) {
  .container {
    flex-direction: column-reverse;
    padding: 16px;
    margin-bottom: 16px;
  }

  .info-wrapper {
    width: 100%;
  }

  .video-wrapper {
    width: 100%;
  }

  .info-desc {
    font-size: 16px;
  }

  .creator-video {
    width: calc(100vw - 32px);
    height: 100%;
    margin-bottom: 2rem;
    max-width: 504px;
  }

  .creator-link {
    width: 100%;
  }
}
