.container {
  position: relative;
  margin-bottom: 128px;
  background: linear-gradient(90deg, #fbfeff 28%, #a6e6f5 62%, #d3f3fa 97%);
  background-repeat: repeat-x;
  background-position: bottom;
}

.section-one {
  position: relative;
  background-image: linear-gradient(-36deg, #d6f0fc 0%, #fbfeff 65%);
}

.section-one-inner {
  display: block;
  margin: 0 auto;
  position: relative;
  padding-bottom: 150px;
  width: 100%;
  max-width: 1920px;
  overflow: hidden;
  padding-top: 8vh;
}

.info-text {
  /** 480 is enough here, changed to 508 for Muhurat campaign emojis */
  max-width: 508px;
  margin: 0 auto;
  margin-left: 0;
  text-align: left;
  padding-bottom: 1.875rem;
  position: relative;
  z-index: 1;
  opacity: 0;
  transform: translateX(-50px);
  transition: all 0.3s ease-out;
}

.info-title {
  composes: text-18 text-light mb8 from global;
}

.info-header {
  composes: mb8 font-semibold line-height-one from global;
  font-size: 50px;
  letter-spacing: -0.05em;
}

.info-footer {
  composes: text-18 lh-169 mb16 from global;
  max-width: 400px;
}

.city-image-container {
  position: absolute;
  right: -5%;
  bottom: 150px;
}

.city-image-container-SCNM_0024 {
  right: -5%;
  bottom: 150px;
}

.city-image-container-SCNM_0025 {
  right: -5%;
  bottom: 150px;
}

.city-image-container-SCAW_0001 {
  right: 0;
  bottom: 166px;
}

.city-image-container-SCNM_0007 {
  right: -3%;
  bottom: 70px;
}

.city-image {
  width: 100%;
  margin-bottom: -4px;
  opacity: 0;
  transform: translateY(12px);
  transition: all 0.3s ease-out;
}

.listof-investers {
  composes: flex mb24 from global;
  flex-direction: column;
}

.btn-grp {
  composes: mb24 from global;
}

.nav {
  composes: flex from global;
}

.broker-logo-container {
  composes: flex from global;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 8px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.05),
    0 2px 6px 0 rgba(0, 0, 0, 0.04);
  background-color: var(--color-white);
}

.broker-logo-container:hover {
  transform: translateY(-1px);
  transition: transform 0.1s ease-out;
}

.broker-tooltip {
  composes: pl8 pr8 z-order-three from global;
  position: absolute;
  display: none;
  white-space: nowrap;
  margin-top: -10px;
  left: -10px;
  color: var(--color-white);
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 4px;
  background-color: var(--color-black);
}

.broker-logo-container:hover .broker-tooltip {
  display: block;
  top: -20px;
}

.fence-section {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 338px;
  width: 100%;
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 0.3s ease-out;
  z-index: 1;
}

.card-container {
  composes: flex from global;
  position: relative;
  justify-content: flex-end;
}

.card {
  z-index: 3;
  position: absolute;
  right: 420px;
  bottom: 16px;
}

.couple-img-container {
  z-index: 2;
  position: absolute;
  bottom: -2px;
  right: 0;
  overflow-y: hidden;
}

.couple-image {
  position: relative;
  opacity: 0;
  transform: translateY(48px);
  transition: all 0.3s ease-out;
}

.city-image-appear {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.city-image-disappear {
  opacity: 0 !important;
  transform: translateY(12px) !important;
}

.couple-image-appear {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.couple-image-disappear {
  opacity: 0 !important;
  transform: translateY(48px) !important;
}

.fence-image-appear {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.fence-image-disappear {
  opacity: 0 !important;
  transform: translateY(48px) !important;
}

.signup-btn {
  composes: text-12 pl0 pointer font-medium from global;
  color: var(--color-blue);
}

.broker-login-text {
  composes: text-13 mt24 from global;
}

.app-info-text {
  composes: text-light mr8 from global;
  /** for aligning text with icons */
  margin-bottom: 2px;
}

.app-rating {
  composes: icon-star-rating text-18 from global;
}

.app-rating::before {
  color: var(--color-gold-light);
}

.broker-logo-showcase {
  composes: mr16 from global;
}

.social-trust-img {
  composes: mob--only from global;
  height: 24px;
}

@media only screen and (max-width: 319px) {
  .social-trust-img {
    display: none;
  }
}

@media only screen and (max-width: 1183px) {
  .info-text-wrapper {
    max-width: 474px !important;
  }

  .fence-section {
    height: 280px;
  }
}

@media (--screen-tabletOrLower) {
  .section-one-inner {
    padding-top: 0;
  }

  .broker-login-text {
    margin-top: 16px;
  }

  .info-text {
    padding-top: 32px;
  }
}

@media only screen and (max-width: 480px) {
  .section-one {
    overflow: hidden;
  }

  .section-one-inner {
    max-width: 100%;

    /* padding-top: 56px; */
    padding-bottom: 80px;
    overflow: hidden;
  }

  .city-image-container-SCNM_0024 {
    right: 0;
  }

  .city-image-container-SCNM_0025 {
    right: 0;
  }

  .city-image-container-SCAW_0001 {
    right: 0;
  }

  .city-image-container-SCNM_0007 {
    right: 0;
  }

  .info-text {
    max-width: 100%;
    padding: 32px 16px 16px 16px;
    margin-left: 0;
  }

  .city-image-container {
    right: 0;
    bottom: -34px;
  }

  .card-container {
    justify-content: center;
    width: 100%;
    bottom: 100px;
  }

  .couple-image {
    max-width: 60%;
  }

  .btn-grp {
    margin-bottom: 16px;
  }

  .fence-section {
    display: none;
  }

  .card {
    right: 0;
    padding: 16px;
    bottom: -72px;
  }

  .info-title {
    font-size: 16px;
  }

  .info-footer {
    max-width: 350px;
    font-size: 16px;
  }

  .info-header {
    font-size: 36px;
  }

  .couple-img-container {
    text-align: center;
    bottom: -38px;
    margin-right: -100px;
  }
}
