.card {
  composes: mr16 from global;
  min-width: 270px;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #e9ecf5;
  background: var(--color-white);
  transition: transform 0.2s ease-out;
}

.card:hover {
  box-shadow: 0 5px 12px 5px rgba(221, 224, 228, 0.5);
  transform: scale(1.05);
  transition: transform 0.2s ease-out;
}

.head {
  composes: flex text-14 mb8 from global;
  justify-content: space-between;
  align-items: flex-start;
}

.name {
  composes: text-14 text-dark font-medium from global;
}

.username {
  composes: text-12 text-light from global;
}

.profile {
  composes: flex from global;
}

.twitter-profile-pic {
  composes: img-32 mr8 mb16 from global;
  border-radius: 50%;
  background-image: url('/public/static/pngs/twitter_sprites.png');
}

.body {
  composes: flex text-14 text-dark lh-169 from global;
  flex-direction: column;
  letter-spacing: -0.01em;
}

.tweet-time {
  composes: text-12 text-light mt16 from global;
}

.body p span {
  cursor: pointer;
  position: relative;
  color: var(--color-blue);
}
